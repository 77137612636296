import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rule', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRule(ctx, ruleData) {
      return new Promise((resolve, reject) => {
        axios.post('/rule', ruleData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            if (error.response.status === 422) {
              if (typeof error.response.data.errors !== 'undefined') {
                const keys = Object.keys(error.response.data.errors)
                const values = Object.values(error.response.data.errors)
                for (let i = 0; i <= keys.length; i += 1) {
                  if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                    reject(new Error(values[0][0]))
                  }
                }
              }
            } else {
              reject(error)
            }
          })
      })
    },
    updateRule(ctx, rule) {
      return new Promise((resolve, reject) => {
        axios.patch(`/rule/${rule.id}`, rule.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status === 422) {
              if (typeof error.response.data.errors !== 'undefined') {
                const keys = Object.keys(error.response.data.errors)
                const values = Object.values(error.response.data.errors)
                for (let i = 0; i <= keys.length; i += 1) {
                  if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                    reject(new Error(values[0][0]))
                  }
                }
              }
            } else {
              reject(error)
            }
          })
      })
    },
    removeRule(ctx, ruleId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/rule/${ruleId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
