<template>
  <div>
    <b-card>
      <div>
        <validation-observer
          ref="checklistRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="8"
              class="mb-2"
            >
              <h5 class="mb-0">
                Rule Details
              </h5>
              <small class="text-muted">
                Enter Title and Rule Items.
              </small>
            </b-col>
            <b-col
              cols="4"
              class="mb-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="validationForm"
              >
                <span>Update</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="ml-2"
                :to="{ name: 'manage-rules' }"
              >
                <feather-icon
                  icon="RevertIcon"
                  class="mr-25"
                />
                <span>Back To list</span>
              </b-button>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Title"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="data_local.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Rule Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-lg-2"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add New</span>
              </b-button>
            </b-col>
          </b-row>
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >

            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >

              <!-- Item Name -->
              <b-col md="10">
                <b-form-group
                  label="Rule Items"
                  label-for="checklist-items"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="checklist item"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="data_local.rules[index].rule"
                      placeholder="Enter New Rule Items"
                      :state="errors.length > 0 ? false:null"
                    >
                      <small
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-textarea>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Remove Button -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index, data_local.rules[index].id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-lg-2"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BFormTextarea,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ruleStoreModule from '../ruleStoreModule'

export default {
  components: {
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      data_local: {
        title: '',
        rules: [],
        removedId: [],
      },
      items: [],
      nextTodoId: 1,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.fetch_checklist_data(this.$route.params.id)
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  setup() {
    const RULE_APP_STORE_MODULE_NAME = 'app-rules'

    // Register module
    if (!store.hasModule(RULE_APP_STORE_MODULE_NAME)) store.registerModule(RULE_APP_STORE_MODULE_NAME, ruleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RULE_APP_STORE_MODULE_NAME)) store.unregisterModule(RULE_APP_STORE_MODULE_NAME)
    })
  },
  methods: {
    fetch_checklist_data(userId) {
      this.$store.dispatch('app-rules/fetchRule', userId)
        .then(res => {
          this.data_local.title = res.data.data.title
          const tempVar = res.data.data.descriptions
          this.formFields(tempVar)
        })
        .catch(err => {
          if (err.response.status === 404) {
            return err
          }
        })
    },
    formFields(tempVar) {
      if (tempVar.length > 0) {
        tempVar.forEach(el => {
          this.data_local.rules.push({ id: el.id, rule: el.description })
          this.items.push({
            id: this.nextTodoId + 1,
          })
          this.nextTodoId += this.nextTodoId
        })
      } else {
        this.items.push({
          id: 1,
        })
        this.data_local.rules.push({ id: '', description: '' })
        this.nextTodoId = 2
      }
      this.initTrHeight()
    },
    validationForm() {
      const self = this
      return new Promise((resolve, reject) => {
        self.$refs.checklistRules.validate().then(success => {
          if (success) {
            self.$store.dispatch('app-rules/updateRule', {
              data: self.data_local,
              id: self.$route.params.id,
            })
              .then(res => {
                if (res.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Rule Added',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: res.data.message,
                    },
                  })
                  self.$router.push('/manage/rules')
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Oops! Rule Adding Failed',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: res.data.message,
                    },
                  })
                }
              })
              .catch(error => {
                // console.log(error)
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Oops! Rule Adding Failed',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error,
                  },
                }, {
                  timeout: 3000,
                })
              })
          } else {
            reject()
          }
        })
      })
    },
    repeateAgain() {
      this.data_local.rules.push({ id: '', description: '' })
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index, hashId) {
      this.items.splice(index, 1)
      if (hashId !== '') {
        this.data_local.removedId.push(hashId)
      }
      this.data_local.rules.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
    .repeater-form {
        overflow: hidden;
        transition: .35s height;
    }
</style>
